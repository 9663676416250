import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default ({ app, store }) => {
  // Set i18n instance on app
  const locale = store.state.locale.locale || navigator.language || navigator.userLanguage || 'ru' // localStorage.getItem('locale')
  app.i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'en',
    messages: {
      en: require('~/locales/en.json'), // store.state.locale.locales.en.messages,
      ru: require('~/locales/ru.json'),
      ua: require('~/locales/ua.json')
    }
  })
}
