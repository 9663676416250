import Vue from 'vue'

// INSTALL MODULES
import ServiceIcon from '~/components/service/ServiceIcon'
import MoneyText from '~/components/common/MoneyText'
// import infiniteScroll from 'vue-infinite-scroll'

Vue.component('ServiceIcon', ServiceIcon)
Vue.component('MoneyText', MoneyText)
// Vue.use(infiniteScroll)
