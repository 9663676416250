<template>
  <div>
    <el-dialog
        :title="$t('Warning')"
        :visible.sync="dialogVisible"
        center
        @close="reset"
    >
      {{ $t('site.idle.message') }}
      <p>
        <span v-if="secondsToExit" v-html="$t('site.idle.countdown', [secondsToExit])" />
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">
          {{ $t('Cancel') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IdleTimeout from 'idle-timeout'

export default {
  props: {
    status: String,
    countdown: {
      type: Number,
      default: 60
    },
    timeout: {
      type: Number,
      default: 20 * 60
    }
  },
  data () {
    return {
      dialogVisible: false,
      secondsToExit: 0,
      resetCount: 0
    }
  },
  computed: {
    ...mapState('multiorder', [
      'inprocess'
    ])
  },
  created () {
    this.idleTimer = new IdleTimeout(this.onIdle, {
      element: document,
      timeout: this.timeout * 1000,
      loop: false
    })
  },
  methods: {
    reset () {
      this.resetCount++
      this.stopCountDown()
      this.idleTimer.timeout = this.timeout * 2 * 1000
      this.idleTimer.reset()
    },
    startCountDown () {
      if (this.inprocess) {
        this.reset()
      }
      this.secondsToExit = this.countdown
      this.interval = setInterval(() => {
        this.secondsToExit--
        if (this.secondsToExit <= 0) {
          this.stopCountDown()
          this.idleReached()
        }
      }, 1000)
    },
    stopCountDown () {
      clearInterval(this.interval)
      this.secondsToExit = 0
    },
    onIdle () {
      // Callback
      this.dialogVisible = true
      this.startCountDown()
    },
    idleReached () {
      this.$emit('onIdleTimeout')
    }
  }
}
</script>
