<template>
  <span style="display: inline-flex">
    <span class="is-money">
      {{ prepared }}
    </span>
    <span v-if="currency" class="is-currency">
      {{ currencyText }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    currency: {
      type: [Number, String]
    },
    digits: {
      type: Number,
      default: 2
    },
    isInteger: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: 64
    }
  },
  computed: {
    prepared () {
      let value = parseFloat(this.value)
      if (this.isInteger) {
        value /= 100
      }
      return this.digits ? value.toFixed(this.digits) : value
    },
    currencyText () {
      if (this.currency && this.currency.id) {
        return this.$t('currency.short.' + this.currency.id)
      }
      return typeof this.currency === 'number' ? this.$t('currency.short.' + this.currency) : this.currency
    }
  }
}
</script>
