import { map } from 'lodash'
import PQueue from 'p-queue'
let queue

export const state = () => ({
  orders: [],
  concurentThreads: 2,
  inprocess: false,
  finished: false,
  maxAmount: null,
  isPaused: false
})

export const mutations = {
  SET_ORDERS: (state, orders) => {
    const o = map(orders, order => {
      return Object.assign({
        inprocess: false,
        service: 'auto',
        status: false,
        state: ''
      }, order)
    })
    state.orders = o
    state.finished = false
    state.inprocess = false
    queue = null
    state.isPaused = false
  },
  REMOVE_ORDER: (state, order) => {
    const index = state.orders.indexOf(order)
    if (index >= 0) {
      state.orders.splice(index, 1)
    }
  },
  UPDATE_ORDER: (state, props) => {
    if (!props || props.length < 2) {
      throw Error('Incoorect props')
    }
    for (const key in props[1]) {
      props[0][key] = props[1][key]
    }
  },
  SET_IN_PROCESS: (state, value) => {
    state.inprocess = !!value
  },
  SET_FINISHED: (state, value) => {
    state.finished = !!value
  },
  SET_CONCURENT_THREADS: (state, value) => {
    state.concurentThreads = value
  },
  SET_MAX_AMOUNT: (state, value) => {
    state.maxAmount = value
  },
  SET_IS_PAUSED: (state, value) => {
    state.isPaused = value
  }
}

export const actions = {
  async orderStatus ({ dispatch, commit, state }, order) {
    try {
      const res = await dispatch('orderStatus', {
        orderguid: order.orderguid
      }, { root: true })
      // STUB
      if (res && res.service && res.service.id) {
        res.service = res.service.id
      }
      commit('UPDATE_ORDER', [order, res])
    } catch (e) {
      commit('UPDATE_ORDER', [order, {
        description: e.message
      }])
    }
  },
  pause ({ commit }) {
    commit('SET_IS_PAUSED', true)
    queue && queue.pause()
  },
  stop ({ commit, dispatch }) {
    queue && queue.clear()
    queue = null
    commit('SET_IN_PROCESS', false)
    commit('SET_FINISHED', true)
    dispatch('reloadBalance', {}, { root: true })
  },
  async makeOrder ({ commit, dispatch, state }, { item, index: i }) {
    // exit when stop called
    if (!state.inprocess) {
      return
    }

    // Check order account
    try {
      commit('UPDATE_ORDER', [item, {
        inprocess: true,
        description: '',
        percentage: 40
      }])

      const res = await dispatch('checkAccount', {
        service: item.service,
        amount: item.amount,
        account: item.account,
        acctype: item.acctype
      }, { root: true })

      if (res.status === 'E') {
        throw new Error(res.descritpion || 'Error check account')
      }
      const guid = await dispatch('generateGuid', {}, { root: true })

      commit('UPDATE_ORDER', [item, {
        service: res.service,
        currency: res.currency,
        dpamount: res.dpamount,
        dpcurrency: res.dpcurrency,
        fee: res.fee,
        orderguid: guid,
        isChecked: true,
        status: 'O',
        state: 'commit',
        percentage: 60
      }])
    } catch (e) {
      commit('UPDATE_ORDER', [item, { inprocess: false, status: 'E', description: e.message, percentage: 100 }])
      return
    }

    if (!state.inprocess) {
      commit('UPDATE_ORDER', [item, { inprocess: false, state: 'canceled' }])
      return
    }

    // Commit order
    try {
      const resc = await dispatch('orderCommit', {
        service: item.service,
        amount: item.amount,
        account: item.account,
        acctype: item.acctype,
        orderguid: item.orderguid
      }, { root: true })

      commit('UPDATE_ORDER', [item, {
        ...resc,
        percentage: 90
      }])

      if (resc.status !== 'C') {
        await dispatch('orderStatus', item)
      }
    } catch (e) {
      commit('UPDATE_ORDER', [item, { inprocess: false, status: 'E', description: e.message, percentage: 100 }])
      return
    }
    commit('UPDATE_ORDER', [item, { inprocess: false, state: 'success', percentage: 100 }])
  },

  async start ({ dispatch, commit, state }) {
    if (queue) {
      commit('SET_IS_PAUSED', false)
      queue.start()
      return
    }
    if (state.inprocess || state.finished) {
      return
    }
    const orders = state.orders.filter(order => {
      return !order.status
    })

    commit('SET_IN_PROCESS', true)

    queue = new PQueue({ concurrency: state.concurentThreads || 2 })

    const functions = orders.map((item, index) => {
      return () => {
        return dispatch('makeOrder', { item, index })
        // if (!state.inprocess) {
        //   return
        // }
        // return new Promise(resolve => setTimeout(() => {
        //   console.log(item._id)
        //   commit('UPDATE_ORDER', [item, { status: 'C', inprocess: false, state: 'success', percentage: 100 }])
        //   resolve()
        // }, randomizeInteger(10, 2000)))
      }
    })
    await queue.addAll(functions)
    queue = null
    commit('SET_IN_PROCESS', false)
    commit('SET_FINISHED', true)
    dispatch('reloadBalance', {}, { root: true })
  }
}
