import nacl from 'tweetnacl-util'
import { hmac } from 'fast-sha256'
import { reduce } from 'lodash'
import Fingerprint2 from 'fingerprintjs2'
import md5 from 'md5'

export function signature (params, token) {
  delete params.sign
  let word = reduce(params, (result, value) => {
    if (value === undefined || value === null) {
      value = ''
    }
    if (value instanceof Date) {
      value = value.toJSON()
    }
    if (value instanceof Array) {
      value = value.join('')
    }
    return result + value
  }, '')
  word = nacl.decodeUTF8(word)
  token = nacl.decodeUTF8(token)
  const sign = hmac(token, word)
  const signHex = Buffer.from(sign).toString('hex')
  return signHex
}

export async function fingerprint (app) {
  // let cookie = require('tiny-cookie')
  // let fingerprint = cookie.get('fingerprint')
  let fingerprint = app.$cookies.get('fingerprint')

  // TO FIX BAD FINGERPRINT
  if (fingerprint && fingerprint.indexOf('[object Object],') >= 0) {
    fingerprint = null
  }

  if (!fingerprint) {
    const newFP = await Fingerprint2.getPromise()
    fingerprint = JSON.stringify(newFP)
    fingerprint = md5(fingerprint)
    // cookie.set('fingerprint', newFP, { expires: '2Y' })
    app.$cookies.set('fingerprint', fingerprint, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365
    })
  }
  return fingerprint
}

export default function ({ $axios, store, app }) {
  $axios.onRequest(async config => {
    const token = store.state.auth.token
    config.data = Object.assign({
      a: config.url.slice(config.url.lastIndexOf('/') + 1)
    }, store.state.auth.params, config.data)

    config.url = ''// config.url.slice(0, config.url.lastIndexOf('/'))
    config.data.userkey = await fingerprint(app)
    const sign = signature(config.data, token)
    config.data.sign = sign
    return config
  })

  $axios.onResponse(response => {
    if (response.status === 200 && response.data) {
      if (response.data.error) {
        throw Error(response.data.error)
      }
    }
    return response
  })

  // $axios.onError(err => {
  //   const code = parseInt(err.response && err.response.status)
  //   if (code === 400) {
  //     redirect('/400')
  //   }
  // })
}
