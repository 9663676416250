import { findIndex, reduce, sortBy, map, keyBy } from 'lodash'
import slug from 'speakingurl'
import createPersistedState from 'vuex-persistedstate'
import createMutationsSharer from 'vuex-shared-mutations'
import { loadingPlugin } from '~/plugins/loading'

export const state = () => ({
  search: '',
  user: null,
  token: null,
  object: null,
  objectName: null,
  services: null,
  providers: null,
  portfolios: null,
  groups: null,
  group: null
})

const getServiceGroups = (services) => {
  return sortBy(reduce(services, (res, service) => {
    var exists = findIndex(res, (t) => {
      return t.id === service.group.id
    })
    if (exists === -1) {
      res.push(service.group)
    }
    return res
  }, [{ id: 0, name: 'All' }]), ['id'])
}

const getProviders = (services) => {
  return sortBy(reduce(services, (res, service) => {
    var exists = findIndex(res, (t) => {
      return t.id === service.provider.id
    })
    if (exists === -1) {
      res.push({
        name: service.provider.name,
        id: service.provider.id,
        images: [service.image],
        services: [service.id],
        group: service.group
      })
    } else {
      res[exists].services.push(service.id)
      res[exists].images.push(service.image)
    }
    return res
  }, []), ['id'])
}

const prepareServices = (services) => {
  return map(services, (service) => {
    service.tags = [slug(service.name).toLowerCase(), slug(service.provider.name).toLowerCase()]
    if (service.isdirectpay) {
      if (service.acctype === ' ') {
        service.acctype = null
      }
    }
    return service
  })
}

// TODO: find proper afterLoad hook in future for first load calling
const firstLoadPlugin = async (obj) => {
  setTimeout(async () => {
    await obj.dispatch('reloadData')
  }, 1000)
}

export const mutations = {
  LOGIN: function (state, data) {
    if (!data.objects || !data.objects.length) {
      throw Error('No one object has assigned to this user')
    }
    this.commit('SET_OBJECT', data.objects[0])
    this.commit('SET_USER', data.user)
    if (data.user.needchangepass) {
      this.$router.push('/profile')
    } else {
      this.$router.push('/')
    }
  },
  LOGOUT: function (state, value) {
    state.object = null
    state.objectName = null
    state.portfolios = null
    state.services = null
    state.user = null
    state.providers = null
    state.groups = null
    state.services = null
    state.multiorder.orders = []
    this.$router.push('/auth/login')
  },
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_PORTFOLIOS: (state, portfolios) => {
    state.portfolios = portfolios
  },
  SET_OBJECT: function (state, object) {
    state.object = object
    state.objectName = object.name
    this.commit('SET_SERVICES', object.services)
    this.commit('SET_PORTFOLIOS', object.portfolios)
  },
  SET_SERVICES: (state, services) => {
    state.services = prepareServices(services)
    state.groups = getServiceGroups(services)
    state.group = state.group || state.groups[0].id
    state.providers = getProviders(services)
  },
  SET_SEARCH: (state, value) => {
    state.search = value
  },
  SET_GROUP: (state, value) => {
    state.group = value
  }
}

export const getters = {
  search (state) {
    return state.search
  },
  isAuthenticated (state) {
    return !!(state.user && state.auth.token)
  },
  user (state) {
    return state.user
  },
  services (state) {
    return state.services
  },
  groups (state) {
    return state.groups
  },
  providers (state) {
    return state.providers
  },
  object (state) {
    return state.object
  },
  servicesByKeys (state) {
    return state.services ? keyBy(state.services, 'id') : {}
  },
  languages (state) {
    if (!this || !this.app) {
      return []
    }
    const messages = this.app.i18n.messages
    const res = {}
    Object.keys(messages).forEach(key => {
      res[key] = {
        short: messages[key]._short_ || key,
        name: messages[key]._name_ || key,
        active: key === state.locale
      }
    })
    return res
  }
}

export const actions = {
  nuxtServerInit ({ commit }, { req }) {
    // commit('SET_USER', {name: 'server'})
    // if (req.session && req.session.authUser) {
    //   commit('SET_USER', req.session.authUser)
    // }
  },
  reloadBalance ({ commit }) {
    return this.$loading('balance', () => {
      return this.$axios.$post('balance', null, { progress: false }).then(res => {
        commit('SET_PORTFOLIOS', res.portfolios)
      })
    })
  },
  async reloadData ({ commit, dispatch, getters }) {
    if (getters.isAuthenticated) {
      try {
        await dispatch('reloadBalance')
        await dispatch('reloadServices')
      } catch (err) {
        this.$message.error(err.message)
        dispatch('auth/logout')
      }
    }
  },
  reloadServices ({ commit }) {
    return this.$loading('services', () => {
      return this.$axios.$post('service', { withfee: 1 }, { progress: false }).then(res => {
        commit('SET_SERVICES', res.data)
      })
    })
  },
  async checkAccount ({ commit, state }, params) {
    // await new Promise(resolve => { setTimeout(resolve, 1000) })
    // return new Error('Некорректная или не активная услуга')
    return this.$axios.$post('account', params, { progress: false }).then(res => {
      if (res.status === 'O') {
        // let service = findServiceById(state.services, res.service) || {id: res.service}
        return Object.assign({}, params, res) // , {service: service})
      } else {
        const message = res.error || res.description || 'It was a problem to check account'
        throw Error(message, res.code)
      }
    })
  },
  orderCommit ({ commit, dispatch, state }, params) {
    // return new Error('Некорректная или не активная услуга')
    return this.$axios.$post('order', params, { progress: false }).then(res => {
      // if (/C|W/.test(res.status)) {
      //   dispatch('reloadBalance')
      // }
      return res
    })
  },
  orderStatus (obj, params) {
    // return new Error('Некорректная или не активная услуга')
    return this.$axios.$post('status', params, { progress: false })
  },
  orderRollback (obj, params) {
    return this.$axios.$post('rollback', params, { progress: false })
  },
  voucherExist (obj, params) {
    return this.$axios.$post('voucherExist', params).then(res => {
      if (res.status === 'O') {
        return Object.assign({}, params, res)
      } else {
        const message = res.error || res.description || 'It was a problem to check voucheer existance'
        throw Error(message, res.code)
      }
    })
  },
  voucher ({ commit, dispatch, state }, params) {
    return this.$axios.$post('voucher', params).then(res => {
      if (/C|W/.test(res.status)) {
        dispatch('reloadBalance')
      }
      return res
    })
  },
  orders ({ dispatch }, params) {
    return this.$axios.$post('orders', params, { progress: false })
  },
  generateGuid () {
    let r, v
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      r = Math.random() * 16 | 0
      v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  },
  report ({ dispatch }, params) {
    const loadingName = 'report' + (params.type ? ' ' + params.type : '')
    return this.$loading(loadingName, () => {
      return this.$axios.$post('report', params, { progress: false })
    })
  }
}

export const plugins = [
  createPersistedState({
    filter: (mutation) => {
      if (mutation.type.startsWith('loading/')) {
        return false
      }
      // const predicate = ['mutation1', 'mutation2']
      // return predicate.indexOf(mutation.type) >= 0;
      return true
    }
  }),
  createMutationsSharer({
    predicate: [
      'LOGIN',
      'LOGOUT',
      'SET_PORTFOLIOS',
      'auth/SET_TOKEN',
      'auth/SET_PARAMS'
    ]
  }),
  loadingPlugin,
  firstLoadPlugin
  // VuexLoading.Store
]
