<template>
  <span class="service-icon">
    <visual
      class="service-icon__image"
      :height="size"
      v-bind="$attrs"
      :image="getImagePath"
      :load="load"
      :alt="service.name"
      :title="service.name + ' (' + service.id + ')'"
    />
    <slot />
  </span>
</template>

<script>
import VueVisual from 'vue-visual'
import NoImage from '~/assets/img/no-image-icon.png'
import LoadingImage from '~/assets/img/loading-dots.gif'
require('vue-visual/index.css')

export default {
  components: {
    visual: VueVisual
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    load: {
      type: [String, Boolean],
      default: 'visible'
    },
    size: {
      type: Number,
      default: 128
    }
  },
  computed: {
    getImagePath () {
      if (this.service.imagePath) {
        const size = [16, 32, 64, 128, 256].find(key => key > this.size)
        const path = (process.env.IMAGES_URL || '') + this.service.imagePath
        return path + size + '.png'
      } else {
        return NoImage
      }
    },
    getLoadingImage () {
      return LoadingImage
    }
  }
}
</script>
<style lang="scss">
  .service-icon {
    display: flex;
  }
  .service-icon__image {
    margin-right: 2px;
    // margin: 3px!important;
    // padding: 0!important;
    // vertical-align: middle;
  }
</style>
