import Vue from 'vue'
import { createVuexLoader, createActionHelpers } from 'vuex-loading'
const helpers = createActionHelpers({
  moduleName: 'loading'
})

const VuexLoading = createVuexLoader({})
Vue.use(VuexLoading)

export default ({ app, store }) => {
  store.$loading = (name, method) => {
    return helpers.startLoading.call(store, store.dispatch, name, method)
  }
}

export const loadingPlugin = VuexLoading.Store
export const startLoading = helpers.startLoading
