<template>
  <div>
    <span v-for="(locale, key) in locales" :key="key" @click="changeLocale(key)">
      {{ locale.short }}
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState('locale', ['locales']),
  methods: {
    changeLocale (key) {
      this.$store.dispatch('locale/change', key).then(() => {
        // this.$router.go()
      })
    }
  }
}
</script>
