<template>
  <div class="b-auth hero is-fullheight auth-container">
    <div class="b-auth-body hero-body">
      <transition name="slide" mode="out-in">
          <nuxt />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  // middleware: ['guest']
}
</script>

<style lang="scss">
  .b-auth {
    align-items: center;
  }
  .b-auth-body {
    width: 100%;
    max-width: 500px;
    .el-checkbox__label {
      color: #e2e5ed;
    }
    .el-form-item__label {
      line-height: 30px;
      padding: 0;
      color: #e2e5ed;
    }
    .title {
      color: #e2e5ed;
    }
  }
    $signup-os-backgrounds: (
        android: url(https://d2ogrdw2mh0rsl.cloudfront.net/images/marshmallow-bg-noblur.jpg),
        realdroid: url(https://d2ogrdw2mh0rsl.cloudfront.net/images/marshmallow-bg-noblur.jpg),
        realios: url(https://d98b8t1nnulk5.cloudfront.net/images/ios8-bg-noblur.jpg),
        ios: url(https://d98b8t1nnulk5.cloudfront.net/images/ios8-bg-noblur.jpg),
        winphone: url(https://d3but80xmlhqzj.cloudfront.net/images/winphone-bg-noblur.jpg),
        win7: url(https://d3but80xmlhqzj.cloudfront.net/images/win-7-bg-noblur.jpg),
        win8: url(https://d3but80xmlhqzj.cloudfront.net/images/win-8-bg-noblur.jpg),
        win8\.1: url(https://d3but80xmlhqzj.cloudfront.net/images/win-8-bg-noblur.jpg),
        win10: url(https://dgzoq9b5asjg1.cloudfront.net/images/win-10-bg.jpg),
        winxp: url(https://dgzoq9b5asjg1.cloudfront.net/images/win-xp-bg-noblur.jpg),
        macelc: url(https://dgzoq9b5asjg1.cloudfront.net/images/el-capitan-bg-noblur.jpg),
        macsie: url(https://dgzoq9b5asjg1.cloudfront.net/images/sierra-bg-noblur.jpg),
        macyos: url(https://d98b8t1nnulk5.cloudfront.net/images/yosemite-bg-noblur.jpg),
        macmav: url(https://dgzoq9b5asjg1.cloudfront.net/images/mavericks-bg-noblur.jpg),
        macml: url(https://d2ogrdw2mh0rsl.cloudfront.net/images/mountain-lion-bg-noblur.jpg),
        maclion: url(https://d3but80xmlhqzj.cloudfront.net/images/lion-bg-noblur.jpg),
        macsl: url(https://d98b8t1nnulk5.cloudfront.net/images/snow-leopard-bg-noblur.jpg)
    );
    .auth-container {
        /*height:100vh;*/
        background-image: url(https://d98b8t1nnulk5.cloudfront.net/images/ios8-bg-noblur.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-color: #698edd!important;

        @each $os, $background in $signup-os-backgrounds {
            &.prev-os-#{$os} {
                background-image: $background;
            }
        }

        button.is-action {
            width: 100%;
        }

        p.title {
            color: white;
        }

        /*a[href] {*/
            /*color: white!important;*/
        /*}*/
        .columns  {
            width: 100%;
        }
        .validation-errors {
            position: absolute;
            top: -2px;
            right: 4px;
            width: 10em;
            text-align: center;
            .validation-error {
                color: #ff3860;
                overflow: hidden;
                /*font-size: 10px;*/
            }
        }
        .tt {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }
</style>
