const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['check-auth'] = require('../middleware/check-auth.js')
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['https'] = require('../middleware/https.js')
middleware['https'] = middleware['https'].default || middleware['https']

middleware['is-mobile'] = require('../middleware/is-mobile.js')
middleware['is-mobile'] = middleware['is-mobile'].default || middleware['is-mobile']

export default middleware
