<template>
  <div>
    <div class="layout-header print-exclude">
      <div class="container">
        <div class="block-company">
          <nuxt-link to="/">
            <span style="position:relative">
              <img src="~/assets/img/logo.png" alt="">
              <span class="app-version">{{ $t('Version') }} {{ appVersion }}</span>
            </span>
          </nuxt-link>
          <Languages class="block-languages" />
        </div>

        <div class="block-right" v-if="isAuthenticated">
          <div class="b-user">
            <div class="block-userinfo">
              <!--<div class="username" style="color: white; font-size: 14px">{{ $t('User') }}: {{user.name}}</div>-->

              <el-dropdown>
                <span class="el-dropdown-link" style="color: white;">
                  <span style="display: inline-block">
                    <div>
                      <span style="color: #b4bccc">
                        {{ $t('User') }}:
                      </span>
                      {{ user.name }}
                    </div>
                    <div>
                      <span style="color: #b4bccc">
                        {{ $t('Terminal') }}:
                      </span>
                      {{ object.code }}
                    </div>
                  </span>
                  <i class="el-icon-more el-icon--right" style="font-size: 25px" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <p style="font-size: 12px; color: #8c939d; text-align: center">
                    {{ object.name }}
                  </p>
                  <el-dropdown-item>
                    <nuxt-link class="username" to="/profile" :title="$t('Settings')">
                      <i class="el-icon-setting" />
                      {{ $t('Settings') }}
                    </nuxt-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div class="signout" :title="$t('Log out')" @click="logout">
                      <i class="fi flaticon-logout" />
                      {{ $t('Log out') }}
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <!--<div class="objectinfo"><span class="objectcode" :title="object.name">{{ $t('Terminal')}}: {{object.code}}</span></div>-->
            </div>
            <!--<div class="signout">-->

            <!--</div>-->
            <!--<div class="signout" @click="logout":title="$t('Log out')"><i class="fi flaticon-logout"></i></div>-->
          </div>
          <Balance class="block-balance" />
        </div>
      </div>
    </div>
    <el-menu :router="true"
        :default-active="$route.path"
        class="el-menu-demo" mode="horizontal"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
      <el-menu-item index="/">
        {{ $t('menu.home') }}
      </el-menu-item>
      <el-submenu index="/orders">
        <template slot="title">
          {{ $t('Orders') }}
        </template>
        <el-menu-item index="/orders">
          {{ $t('Orders') }}
        </el-menu-item>
        <el-menu-item index="/multiorder">
          {{ $t('Plural order') }}
        </el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          {{ $t('menu.reports') }}
        </template>
        <el-menu-item index="/report/sales">
          {{ $t('report.sales') }}
        </el-menu-item>
        <el-menu-item index="/report/bydays">
          {{ $t('report.bydays') }}
        </el-menu-item>
        <el-menu-item index="/report/portfolio">
          {{ $t('report.portfolio') }}
        </el-menu-item>
        <el-menu-item index="/report/account">
          {{ $t('report.account') }}
        </el-menu-item>
      </el-submenu>
    </el-menu>

    <IdleLogout v-if="!rememberme" @onIdleTimeout="onIdleTimeout" />
  </div>
</template>
<script>

import { mapGetters, mapState } from 'vuex'
import Balance from './Balance.vue'
import Languages from './Languages.vue'
import IdleLogout from './IdleLogout.vue'

export default {
  data () {
    return {
      menuShow: false
    }
  },
  components: {
    Balance,
    Languages,
    IdleLogout
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'object',
      'user'
    ]),
    ...mapState('auth', ['rememberme']),
    appVersion () {
      return process.env.APP_VESRION
    }
  },
  methods: {
    clickOutside () {
      this.menuShow = false
    },
    search (e) {
      console.warn(e)
      // this.$store.dispatch('search',
    },
    logout () {
      this.$confirm(this.$t('confirm.quit'), this.$t('Log out'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        type: 'warning'
      }).then(() => {
        this.$router.push('/auth/logout')
      })
    },
    onIdleTimeout () {
      this.$router.push('/auth/logout')
    }
  }
}
</script>
<style lang="scss">
  @media screen and (max-width: 789px) {
    .block-company {
      flex-direction: column!important;
    }
  }
  .layout-header {
    padding: 0 30px;
    background-color: #7c838a;
    .block-company {
      display: flex;
      flex-direction: row;
      .block-languages {
        color: #66bcea;
        margin-left: 10px;
        display: flex;
        align-items: flex-end;
        span {
          cursor: pointer;
          padding: 3px;
          font-size: 15px;
        }
      }
      .app-version {
        position: absolute;
        color: #555;
        font-size: 8px;
        top: 13px;
        right: 0px;
      }
    }
    .container {
      display: -webkit-flex; /* Safari */
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .block-right {
      display: flex;
      flex-direction: column;
      text-align: right;
      .b-user {
        >* {
          display: inline-block;
        }
      }
      .block-userinfo {
        text-align: right;
        color: #fff;
        .username {
          color: inherit;
          font-size: 16px;
          cursor: pointer;
        }
        .objectinfo {
          font-size: 16px;
          // .objectcode {
          //   padding-right: 4px;
          // }
        }
      }

      .signout {
        padding-top: 3px;
        padding-left: 11px;
        font-size: 30px;
        color: #fff;
        vertical-align: top;
        cursor: pointer;
      }

      .block-balance {
        min-height: 20px;
        cursor: pointer;
        color: #ffbf05;
        font-size: 14px;
        padding: 1px 0px;
        .balance-item {
          padding: 0 3px;
          display: inline-block;
        }
      }
    }
  }
</style>
