import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _71bcfceb = () => interopDefault(import('../pages/multiorder.vue' /* webpackChunkName: "pages/multiorder" */))
const _202b0dca = () => interopDefault(import('../pages/orders.vue' /* webpackChunkName: "pages/orders" */))
const _f810b53a = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _34e7abea = () => interopDefault(import('../pages/report.vue' /* webpackChunkName: "pages/report" */))
const _0fe04208 = () => interopDefault(import('../pages/report/account.vue' /* webpackChunkName: "pages/report/account" */))
const _6e7f7ca3 = () => interopDefault(import('../pages/report/bydays.vue' /* webpackChunkName: "pages/report/bydays" */))
const _3af25a3a = () => interopDefault(import('../pages/report/portfolio.vue' /* webpackChunkName: "pages/report/portfolio" */))
const _0ea28e27 = () => interopDefault(import('../pages/report/sales.vue' /* webpackChunkName: "pages/report/sales" */))
const _80fa989c = () => interopDefault(import('../pages/service/index.vue' /* webpackChunkName: "pages/service/index" */))
const _a23928b8 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _766594f8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _b943daea = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _b9a40acc = () => interopDefault(import('../pages/service/_id.vue' /* webpackChunkName: "pages/service/_id" */))
const _c780eee8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/multiorder",
    component: _71bcfceb,
    name: "multiorder"
  }, {
    path: "/orders",
    component: _202b0dca,
    name: "orders"
  }, {
    path: "/profile",
    component: _f810b53a,
    name: "profile"
  }, {
    path: "/report",
    component: _34e7abea,
    name: "report",
    children: [{
      path: "account",
      component: _0fe04208,
      name: "report-account"
    }, {
      path: "bydays",
      component: _6e7f7ca3,
      name: "report-bydays"
    }, {
      path: "portfolio",
      component: _3af25a3a,
      name: "report-portfolio"
    }, {
      path: "sales",
      component: _0ea28e27,
      name: "report-sales"
    }]
  }, {
    path: "/service",
    component: _80fa989c,
    name: "service"
  }, {
    path: "/auth/forgot",
    component: _a23928b8,
    name: "auth-forgot"
  }, {
    path: "/auth/login",
    component: _766594f8,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _b943daea,
    name: "auth-logout"
  }, {
    path: "/service/:id",
    component: _b9a40acc,
    name: "service-id"
  }, {
    path: "/",
    component: _c780eee8,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
